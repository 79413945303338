@import url(https://fonts.googleapis.com/css?family=Roboto:100,100italic,300,300italic,400,400italic,500,500italic,700,700italic,900,900italic&subset=latin,latin-ext,cyrillic,cyrillic-ext,greek-ext,greek,vietnamese);

html,
body,
#app {
    height: 100%;
    min-width: 1420px;
    font-size:18px;
    font-family: 'roboto';
    background: #f6f7f9;
}

h1,
h2,
h3,
h4 {
    text-align: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

input:-webkit-autofill,
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset;
}

textarea:focus, input:focus{
    outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.container {
    position: relative;
    width: 1420px;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px 20px 20px;
}

.parent-container {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    height: 100%;
}

@media (max-width: 1170px) {
    .container {
        width: 100%;
        margin: 0;
        padding: 10px;
    }
    .container.navbar {
        padding: 10px;

    }
    h1,
    h2,
    h3,
    a {
        padding: 10px;
    }
    .center-block {
        padding: 10px;
    }
}




.responsive-table {
    overflow-x: auto;
    max-width: 100%;
}

.center-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}

table {
    width: 100%;
    margin: 20px 0 20px 0;

}


div.center-block form input,
select {
    margin: 5px;
}

div.center-block form div {
    text-align: center;
}

.pure-control-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

div.-pagination {
    height: 40px;
}

select {
    width: 215.6px;
    height: 36.4px;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.button-success,
.button-error {
    color: white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}

.button-error {
    background: rgb(202, 60, 60);
}

.button-success {
    background: rgb(28, 184, 65);
}

/* .popup-content {
    width: 300px !important;
} */

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    padding: 10px 5px;
    text-align: center;
}

.modal>.button-group {
    padding: 10px 5px;
    display: flex;
    justify-content: space-evenly;
}

div.rt-td {
    text-align: center
}

.logout{
   cursor: pointer;
}

.logout:hover{
  color: #d6393f;
  text-decoration: underline;
}

.logout:active{
  color: #BB3D42;
}

.language:hover{
    text-decoration: underline;

}
.language{
 padding-right: 5px;
 cursor: pointer;
}

.blockButton{
  border: 1px solid #CD3A47;
  background-color: #ffffff;
  color: #CD3A47;
  border-radius: 2px;
  padding: .5em 1em;
  outline: none;
}

.blockButton:hover{
  border: 1px solid #CD3A47;
  background-color: #CD3A47;
  color: #ffffff;
}

.blockButton:active{
  border: 1px solid #ad1a1c;
  background-color: #ad1a1c;
  color: #ffffff;
  outline: none;
}

.backButton{
  border: 1px solid #B1D6FF;
  background-color: #ffffff;
  border-radius: 2px;
  padding: .5em 1em;
  outline: none;
  text-decoration: none;
  color: #000000;
}

.backButton:hover{
  background-color: #5AA7FF;
  color: #ffffff;
}

.backButton:active{
  border: 1px solid #506580;
  background-color: #506580;
  color: #ffffff;
}

.primaryButton{
  border: 1px solid #9EC6FC;
  background-color: #9EC6FC;
  padding: .5em 1em;
  outline: none;
  border-radius: 2px;
  text-decoration: none;
  color: #000000;
}

.primaryButton:hover{
  border-radius: 1px solid #5AA7FF;
  background-color: #5AA7FF;
  color: #ffffff;
}

.primaryButton:active{
  border-radius: 1px solid #506580;
  background-color: #506580;
  color: #ffffff;
  outline: none;
}

.unBlockButton{
  border:1px solid #299b4c;
  background-color: #ffffff;
  color: #207c3c;
  padding: .5em 1em;
  outline: none;
  border-radius: 2px;
}

.unBlockButton:hover{
  border: 1px solid #299b4c;
  background-color: #299b4c;
  color: #ffffff;
  outline: none;
}

.unBlockButton:active{
  border: 1px solid #117c31;
  background-color: #117c31;
  color: #ffffff;
}


